<template>
  <div>
    <vx-card actionable class="cardx" title="Loan Application Check">
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Mobile</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="text"
                id="feesselect"
                class="w-full"
                placeholder="Mobile"
                v-model="mobile"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Email</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="email"
                id="feesselect"
                class="w-full"
                placeholder="Email"
                v-model="email"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Full Name</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="text"
                id="feesselect"
                class="w-full"
                placeholder="Full Name"
                v-model="full_name"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Gender</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-radio
                style="margin-right: 3%"
                color="dark"
                v-model="radio3"
                vs-value="1"
                >Male</vs-radio
              >
              <vs-radio color="dark" v-model="radio3" vs-value="2"
                >Female</vs-radio
              >
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>DOB</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="date" id="birthday"
                class="w-full"
                v-model="date_of_birth"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>PAN</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="text"
                id="feesselect"
                class="w-full"
                placeholder="PAN Number"
                v-model="pan_number"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Address</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-textarea
                type="text"
                id="feesselect"
                class="w-full"
                placeholder="Address"
                v-model="address"
                
              />
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Pin Code</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="number"
                id="feesselect"
                class="w-full"
                placeholder="Pin Code"
                v-model="pin_code"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 2%">
            <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-w="6"
            >
            <span>Loan Amount</span>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="6"
            >
              <vs-input
                type="number"
                id="feesselect"
                class="w-full"
                placeholder="Amount"
                v-model="loan_amount"
                
              ></vs-input>
            </vs-col>
        </vs-row>
        <vs-row type="flex" style="margin-top: 3%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
            <vs-button
                class="float-center"
                color="primary"
                type="gradient"
                @click="LoanCheck()"
                >CHECK</vs-button
              >
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="4"
            >
              
            </vs-col>
          </vs-row>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
// import axios from "axios";
// import constants from "../../constants.json";
// import eventbus from "../components/eventbus";
export default {
  mounted() {
    
  },
  data() {
    return {
        loan_amount: "",
        mobile: "",
        email: "",
        full_name: "",
        radio3: "1",
        pan_number: "",
        address: "",
        pin_code: "",
        date_of_birth: ""
    };
  },
  components: {
    "v-select": vSelect
  },
  watch: {
  },
  methods: {
    LoanCheck() {
      if (
        !this.validateName(this.full_name) ||
        !this.validateNumber(this.mobile) ||
        !this.pinCode(this.pin_code)
      ) {
        return;
      }
      if (
        this.mobile === "" ||
        this.full_name === "" ||
        this.email === "" ||
        this.radio3 === "" ||
        this.date_of_birth === "" ||
        this.pan_number === "" ||
        this.address === "" ||
        this.pin_code === "" ||
        this.loan_amount === ""
      ) {
        this.$vs.notify({
          title: "Fill all the required forms",
          text: "please fill all the required forms to continue loan check",
          color: "danger",
          position: "top-center",
        });
      } else {
        this.post_API_obj = {
              mobile: this.mobile,
              email: this.email,
              full_name: this.full_name,
              gender: this.radio3,
              date_of_birth: this.date_of_birth,
              pan_number: this.pan_number,
              address: this.address,
              pin_code: this.pin_code,
              loan_amount: this.loan_amount
            };
      }
      console.log("Loan Data",this.post_API_obj);
    },
    pinCode(pincode) {
      if (pincode.length == 6) {
        pincode = pincode.substr(pincode.length - 6);
        if (parseInt(pincode)) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter a valid Pin Code",
            text: "Enter a valid Pin Code to continue",
            color: "danger",
            position: "top-center",
          });
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Number should be atleast 6 characters in length",
          text: "Enter a valid valid",
          color: "danger",
          position: "top-center",
        });
        return false;
      }
    },
    validateNumber(number) {
      if (number.length >= 8) {
        number = number.substr(number.length - 8);
        if (parseInt(number)) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter a valid number",
            text: "Enter a valid number to continue",
            color: "danger",
            position: "top-center",
          });
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Number should be atleast 8 characters in length",
          text: "Enter a valid number",
          color: "danger",
          position: "top-center",
        });
        return false;
      }
    },
    validateName(name) {
      if (this.allLetter(name)) {
        if (name.length >= 8) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter a valid Name to continue",
            text: "Please Fill Full Name",
            color: "danger",
            position: "top-center",
          });
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Enter a valid name",
          text: "Enter a valid Name to continue",
          color: "danger",
          position: "top-center",
        });
        return false;
      }
    },

    allLetter(text) {
      var letters = /^[A-Za-z ]+$/;
      if (text.match(letters)) {
        return true;
      } else {
        return false;
      }
    },
    validateEmail(email) {
      if (this.allLetter(email)) {
        if (email.length >= 1) {
          return true;
        } else {
          this.$vs.notify({
            title: "Enter a valid Email to continue",
            text: "Please Fill With valid Email",
            color: "danger",
            position: "top-center",
          });
          return false;
        }
      } else {
        this.$vs.notify({
          title: "Enter a valid Email",
          text: "Enter a valid Email to continue",
          color: "danger",
          position: "top-center",
        });
        return false;
      }
    },
    // allEmail(text) {
    //   var letters = /^[A-Za-z]+@[a-z0-9.-]+$/;
    //   if (text.match(letters)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
</style>
